<template>
  <v-radio-group column>
    <v-card outlined color="rgb(0,0,0,0)">
      <div v-if="addressList.length == 0" width="100%">
        <span class="d-flex justify-center my-12" style="font-size: 16px; font-weight: bold"
          >新しい住所を追加してください</span
        >
      </div>
      <template v-if="apierror.status === 'error'">
        <div v-for="msg of apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-radio-group v-model="id">
        <v-row v-for="(address, index) in displayLists" :key="index">
          <v-col cols="12" sm="8">
            <v-radio class="d-flex align-start mb-6" color="#ff6e40" :value="address.addr_id">
              <template #label>
                <div style="line-height: 28px; font-size: 15px">
                  <span style="font-size: 16px">
                    <strong>{{ address.addr_sei }} {{ address.addr_mei }}</strong>
                  </span>
                  <strong v-if="address.addr_default === 1" style="color: #ff6e40; font-size: 14px"> ( 自宅 )</strong>
                  <br />
                  <span>{{ address.addr_zip }}</span>
                  <br />
                  <span>{{ address.addr_addr1 }}</span>
                  <br />
                  <span v-if="address.addr_addr2 !== null">{{ address.addr_addr2 }}</span>
                  <br v-if="address.addr_addr2 !== null" />
                  <span v-if="address.addr_company !== null">{{ address.addr_company }}</span>
                  <br v-if="address.addr_company !== null" />
                  <span v-if="address.addr_department !== null">{{ address.addr_department }}</span>
                  <br v-if="address.addr_department !== null" />
                  <span>{{ address.addr_telno }}</span>
                  <br />
                </div>
              </template>
            </v-radio>
          </v-col>
          <v-col cols="12" sm="4">
            <div v-if="address.addr_default !== 1" class="d-flex justify-end">
              <v-btn depressed color="#ff6e40" width="100px" class="mb-4" @click="editItem(address)">
                <span style="color: #fff">変更</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-card>
    <div>
      <v-btn depressed style="width: 180px; background-color: #ff6e40" @click="addresscreate">
        <span style="color: #fff">新しいお届け先を追加</span>
      </v-btn>
    </div>
    <v-pagination
      v-if="displayLists.length > 0"
      v-model="page"
      class="my-6"
      :length="length"
      :total-visible="5"
      color="#ff6e40"
      @input="pageChange"
    ></v-pagination>
    <div class="mt-10">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="6" sm="3" class="d-flex justify-end">
          <v-btn outlined width="100%" @click="back">
            <span>戻る</span>
          </v-btn>
        </v-col>
        <v-col cols="6" sm="3" class="d-flex justify-end">
          <v-btn v-if="id === '' || id === undefined" color="#ff6e40" width="100%" depressed disabled>
            <span style="color: #fff">次へ</span>
          </v-btn>
          <v-btn
            v-if="id !== '' && id !== undefined"
            color="#ff6e40"
            width="100%"
            depressed
            :loading="loading"
            @click="nextPage"
          >
            <span style="color: #fff">次へ</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog1" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> 商品を追加してください。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="dialog1 = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> 送付先を選択してください。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="dialog2 = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-radio-group>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  props: ['e1', 'form', 'postage'],
  data: () => ({
    id: '',
    loading: false,
    dialog1: false,
    dialog2: false,
    order: {
      pref_code: '',
      details: [],
    },
    List: [],
    displayLists: [],
    page: 1,
    length: 0,
    pageSize: 3,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('cartStore', ['selectedAddress']),
    ...mapState('addressStore', ['addressList']),
    ...mapState('shippingFee', ['ShippingFee']),
    ...mapState('cartStore', ['count', 'cartList']),
  },
  watch: {
    // id(newValue) {
    //   console.log('watch id:', newValue)
    //   this.selectAddress(this.id)
    //   this.apierror.status = ''
    //   this.apierror.code = ''
    //   this.apierror.messages = []
    // },
  },
  created() {
    this.refreshList()
  },
  methods: {
    ...mapMutations('cartStore', ['selectAddress']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('addressStore', ['loadAddressList']),
    ...mapActions('shippingFee', ['loadShippingFee']),

    refreshList() {
      this.loadAddressList().then(() => {
        this.List = [...this.addressList]
        this.displayLists = this.List.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
        this.length = Math.ceil(this.List.length / this.pageSize)

        // if (this.selectedAddress !== '') {
        //   this.id = this.selectedAddress
        // }
      })
    },
    pageChange(pageNumber) {
      this.displayLists = this.List.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber)

      // do rendering
      const temp = this.id
      this.id = ''
      this.$nextTick(() => {
        this.id = temp
      })

      window.scrollTo(0, 0)
    },
    refreshDelete() {
      this.setOverlayStatus(true)
      this.loadAddressList(this.$route.query).then(() => {
        this.setOverlayStatus(false)
      })
    },
    editItem(item) {
      this.$router.push({
        name: 'addressedit',
        params: {
          id: item.addr_id,
          query: this.$route.query,
        },
      })
    },
    addresscreate() {
      this.$router.push('/addresscreate')
    },
    nextPage() {
      console.log('nextPage:', this.addressList, this.id)
      this.loading = true
      if (this.cartList.length === 0) {
        this.dialog1 = true
        this.loading = false
      } else if (this.id !== '') {
        const i = this.addressList.map(obj => obj.addr_id).indexOf(this.id)
        this.order.pref_code = this.addressList[i].prefcode
        console.log('prefcode:', this.order.pref_code)
        this.order.details = []
        this.cartList.forEach((meal, index) => {
          this.product = {
            product_code: meal.product_code,
            order_qty: this.cartList[index].count,
          }
          this.order.details.push(this.product)
        })
        this.loadShippingFee(this.order)
          .then(() => {
            this.$emit('getForm', this.addressList[i], this.ShippingFee.data.total)
            this.$emit('e1Change', 2)
            this.loading = false
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      } else {
        this.dialog2 = true
      }
    },
    back() {
      this.$router.push('/cart')

      // this.sf = 0
    },
  },
}
</script>
