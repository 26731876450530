<template>
  <v-stepper-content :step="step">
    <v-card outlined class="mb-6" color="rgb(0,0,0,0)">
      <v-card outlined color="rgb(0,0,0,0)">
        <div class="d-flex align-center mb-3 pl-3" style="height: 40px; background-color: #ebe9e8">
          <strong>注文商品</strong>
        </div>
        <div v-if="cartList.length == 0" width="100%">
          <span class="d-flex justify-center my-12" style="font-size: 16px; font-weight: bold">
            商品を追加してください。
          </span>
        </div>
        <v-card v-for="(product, index) in cartList" :key="index" width="100%" outlined color="rgba(0,0,0,0)">
          <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">
              <div style="height: 100%; width: 100%" class="d-flex align-center">
                <v-img :src="product.load" class="d-flex align-center"></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="4" lg="5" sm="6" class="mt-2">
              <div style="font-size: 18px; font-weight: bold">
                <span>{{ product.name }}</span>
              </div>
              <div class="d-flex align-center my-3">
                <span style="font-size: 14px">数量</span>
                <span class="ml-2" style="font-size: 24px">
                  {{ product.count }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" class="mt-1">
              <div class="d-flex align-center justify-end">
                <span style="font-size: 14px">税込単価</span>
                <span class="ml-2" style="font-size: 24px">
                  ￥{{ product.price_normal ? product.price_normal.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '' }}</span>
              </div>
              <div class="d-flex align-center justify-end my-3">
                <span style="font-size: 14px">小計</span>
                <span class="ml-2" style="font-size: 24px">
                  ￥{{ cal(product) ? cal(product).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '' }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
        </v-card>
        <div class="d-flex align-center pl-3" style="height: 40px; background-color: #ebe9e8">
          <strong>クーポン</strong>
        </div>
        <v-row class="mt-4 mb-3 px-3">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" class="d-flex align-start">
            <div>
              <strong>お手持ちのクーポンコードを入力してください。</strong>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div style="max-width: 400px">
              <v-text-field
                v-model="couponComputed"
                placeholder="クーポンコード"
                hide-details="auto"
                outlined
                dense
                color="#ff6e40"
              ></v-text-field>
            </div>
            <p v-if="coupon && errorMsg" style="margin: 5px 0; color: red; font-size: 12px">
              {{ errorMsg }}
            </p>
          </v-col>
          <v-col cols="12" sm="2">
            <div>
              <v-btn color="#ff6e40" width="50px" depressed @click="getCouponInfo()">
                <span style="color: #fff">適用</span>
              </v-btn>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <div class="d-flex align-center mb-3 pl-3" style="height: 40px; background-color: #ebe9e8">
          <strong>送付先</strong>
        </div>
        <div v-if="finished === true" width="100%">
          <span class="d-flex justify-center my-12" style="font-size: 16px; font-weight: bold">
            送付先を選択してください。</span>
        </div>
        <div v-if="finished === false" style="line-height: 28px; font-size: 15px" class="px-3">
          <span style="font-size: 16px">
            <strong>{{ form.addr_sei }} {{ form.addr_mei }}</strong>
          </span>
          <br />
          <span>{{ form.addr_zip }}</span>
          <br />
          <span>{{ form.addr_addr1 }}</span>
          <br />
          <span v-if="form.addr_addr2 !== null">{{ form.addr_addr2 }}</span>
          <br v-if="form.addr_addr2 !== null" />
          <span>{{ form.addr_telno }}</span>
          <br />
        </div>
        <div class="mt-10">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" class="d-flex justify-end"> </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <!-- <v-btn color="#ff6e40" width="100%" depressed @click="nextPage">
                <span style="color: #fff">商品追加</span>
              </v-btn> -->
              <v-btn outlined width="100%" @click="lastPage">
                <span>戻る</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" width="500px" persistent>
            <v-card height="180px" class="d-flex align-center justify-center">
              <div>
                <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
                  <span> 商品を追加してください。 </span>
                </div>
                <div class="d-flex justify-center mt-8">
                  <v-btn color="#ff6e40" width="80px" depressed @click="dialog = false">
                    <span style="color: #fff">OK</span>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </v-card>
    <v-snackbar v-model="showError" :timeout="3000" color="red accent-2">
      <div class="text-center">もう一度お試しください!</div>
    </v-snackbar>
    <v-dialog v-model="finished" persistent width="500">
      <v-card height="220px" class="d-flex align-center justify-center">
        <div>
          <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 20px">
            <span>ご購入ありがとうございます。</span>
          </div>
          <div class="d-flex justify-center mt-8">
            <v-btn color="#ff6e40" width="80px" depressed @click="back">
              <span style="color: #fff">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-stepper-content>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  props: ['form', 'step', 'postage', 'discount', 'submitStatus', 'submitOrder', 'loading', 'cancelCoup'],
  data: () => ({
    errorMsg: '',
    finished: false,
    dialog: false,
    pushCoupon: true,
    showError: false,
    order: {
      addr_id: '',
      codes: [],
      details: [],
    },
    product: {
      product_code: '',
      order_qty: '',
    },

    coupon: '',
  }),
  computed: {
    ...mapState('cartStore', ['count', 'cartList']),
    ...mapState('shippingFee', ['ShippingFee']),
    ...mapState('couponStore', ['Info']),
    couponComputed: {
      get() {
        return this.coupon
      },
      set(value) {
        this.coupon = value.toUpperCase()
      },
    },
  },
  watch: {
    submitStatus() {
      if (this.submitStatus === true) {
        this.submit()
      }
    },
    coupon() {
      this.errorMsg = ''
    },
    cancelCoup() {
      this.cancelCoupon(this.cancelCoup)
    },
  },
  methods: {
    ...mapActions('orderStore', ['createOrder']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('cartStore', ['increCount', 'decreCount', 'clearNavCount']),
    ...mapMutations('shippingFee', ['clearShippingFee']),
    ...mapActions('couponStore', ['getInfo']),
    getCouponInfo() {
      if (this.coupon) {
        let hasSubscription = false
        this.cartList.forEach(cl => {
          if (cl.product_code.substring(0, 3) === 'SUB') {
            hasSubscription = true
          }
        })
        if (!hasSubscription) {
          this.errorMsg = 'お試しセットにクーポンは適用できません。'

          // this.$emit('getCouponError', this.errorMsg)
          return
        }
        if (this.order.codes.length > 0) {
          // open CouponDialog

          /* eslint-disable */
          this.errorMsg =
            '1回の注文につきクーポン1枚のみの使用が可能です。複数のクーポンを同時に使用することはできません。'

          return
        }
        this.setOverlayStatus(true)
        this.getInfo({
          coupon_code: this.coupon,
        })
          .then(() => {
            this.pushCoupon = true
          })
          .catch(err => {
            console.log(err)
            if (err.response) {
              console.log(err)
              // eslint-disable-next-line prefer-destructuring
              this.errorMsg = err.response.data.message[0]

              // this.$emit('getCouponError', errorMsg)
            }
            this.pushCoupon = false

            // this.coupon = ''
          })
          .finally(() => {
            if (this.pushCoupon === true) {
              const index = this.order.codes.findIndex(obj => obj === this.coupon)
              if (index === -1) {
                this.order.codes.push(this.Info.coup_code)
              }

              this.coupon = ''
              this.$emit('getDiscount', this.Info)
            }
            this.setOverlayStatus(false)
          })
      }
    },
    incr(index) {
      if (this.cartList[index].id !== 'SET-TRIAL001' && this.cartList[index].count < 10) {
        this.cartList[index].count += 1
        this.increCount()
      }
    },
    desc(index) {
      if (this.cartList[index].count > 0) {
        if (this.cartList[index].count === 1) {
          this.del(this.cartList[index].id, this.cartList[index].count)
        }
        this.cartList[index].count -= 1
        this.decreCount()
      }
    },
    del(id, value) {
      const index = this.cartList.findIndex(product => product.id === id)
      this.cartList.splice(index, 1)
      this.clearNavCount(value)
    },

    cal(product) {
      return product.price_normal * product.count
    },
    back() {
      this.$router.push('/')
    },
    lastPage() {
      this.$emit('doSubmitOrder')
      this.$emit('prevPage', this.step)

      this.coupon = ''
    },
    nextPage() {
      this.$router.push('/')
    },
    cancelCoupon(val) {
      const index = this.order.codes.findIndex(obj => obj.codes === val.coup_codes)
      this.order.codes.splice(index, 1)
      this.$emit('getDiscount', val)
    },
    submit() {
      console.log('check.vue submit')
      if (this.cartList.length !== 0) {
        this.order.addr_id = this.form.addr_id
        this.cartList.forEach((item, i) => {
          this.product = {
            product_code: item.product_code,
            order_qty: this.cartList[i].count,
          }
          this.order.details.push(this.product)
        })
        this.$emit('isLoading')
        this.createOrder(this.order)
          .then(() => {
            this.cartList.splice(0, this.cartList.length)
            this.clearNavCount(this.count)
            this.clearShippingFee()
            this.$emit('clearPostage', 0)
            this.finished = true
          })
          .catch(err => {
            console.log(err)
            this.showError = true
          })
          .finally(() => {
            this.$emit('isLoading')
          })
      } else {
        this.dialog = true
      }
    },
  },
}
</script>
