<template>
  <v-stepper-content :step="step">
    <v-card outlined color="rgb(0,0,0,0)" class="px-2">
      <v-card outlined color="rgb(0,0,0,0)">
        <v-container class="mt-10">
          <v-row>
            <v-col cols="12">
              <PaymentComponent></PaymentComponent>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="6" sm="3">
              <v-btn outlined width="100%" @click="prevPage()">
                <span>戻る</span>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3">
              <v-btn color="primary" depressed width="100%" :loading="loading" @click="nextPage()">
                <span style="color: white">次へ</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-dialog v-model="dialog" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> クレジットカードを追加してください。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="dialog = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </v-stepper-content>
</template>
<script>
import { mapState } from 'vuex'
import { isEmpty } from '@/utils'
import PaymentComponent from '../../payment/PaymentComponent.vue'

export default {
  components: {
    PaymentComponent,
  },
  props: ['step', 'submitOrder', 'postage'],
  data: () => ({
    loading: false,
    dialog: false,
    order: {
      addr_id: '',
      details: [],
    },
    product: {
      product_code: '',
      order_qty: '',
    },
  }),
  computed: {
    ...mapState('card', ['cardInfo']),
    hadCard() {
      if (isEmpty(this.cardInfo)) {
        return false
      }

      return this.cardInfo.length !== 0
    },
  },
  methods: {
    prevPage() {
      this.$emit('clearPostage', 0)
      this.$emit('prevPage', this.step)
    },
    nextPage() {
      if (this.hadCard) {
        this.$emit('doSubmitOrder')
        this.$emit('nextPage', this.step)
        this.loading = false
      } else {
        this.dialog = true
        this.loading = false
      }
    },
  },
}
</script>
