<template>
  <div>
    <v-card-text class="pa-5">
      <v-card
        v-if="!isEdit"
        style="border-style: dashed; cursor: pointer; border-color: #ff6e40"
        class="d-flex align-center justify-center flex-grow-1 flex-column pa-3"
        @click="isCardOpen = true"
      >
        <v-avatar size="64" class="elevation-5 mb-6 bg-card">
          <v-icon size="32" class="rounded-0" color="primary">
            {{ icons.mdiPlus }}
          </v-icon>
        </v-avatar>
        <p id="add-card-text" purple>クレジットカード追加</p>
      </v-card>
      <v-card
        v-for="(paymentCard, index) in paymentCards"
        :key="paymentCard.id"
        outlined
        :class="index < paymentCards.length - 1 ? 'mb-4' : ''"
      >
        <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
          <div class="mb-3 mb-sm-0">
            <div class="text--primary font-weight-medium mt-2">
              <span class="text-base me-2">{{ paymentCard.owner }}</span>
            </div>
            <span>
              <span>{{ paymentCard.cardNo }}</span>
            </span>
          </div>

          <v-spacer></v-spacer>
          <div class="text-sm-right">
            <v-btn color="primary" outlined class="mb-5" @click="editCardDetails(paymentCard.id)"> 変更 </v-btn>
            <v-btn color="secondary" outlined class="ms-3 mb-5" :loading="deleteLoading" @click="handleDeleteCard()">
              削除
            </v-btn>
            <p class="mb-0">
              <span class="me-1">有効期限(月/年)</span>
              <span>{{ expiredDate(paymentCard.cardExpires) }}</span>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <!-- </v-card> -->

    <!-- credit-card -->
    <PaymentCardDetail
      :is-card-open.sync="isCardOpen"
      :card-detail="cardDetail"
      :action="isEdit ? 'edit' : 'create'"
    ></PaymentCardDetail>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiSquareEditOutline } from '@mdi/js'
import { ref } from 'vue'

import { mapState, mapActions } from 'vuex'

import PaymentCardDetail from './PaymentCardDetail.vue'

export default {
  components: {
    PaymentCardDetail,
  },
  setup() {
    // const store = useStore()
    const paymentCards = ref([])
    const deleteLoading = ref(false)
    const isEdit = ref(false)
    const isEditAddressDialogVisible = ref(false)
    const cardDetail = ref({
      id: '',
      owner: '',
      cardNo: '',
      cardExpires: '',
      CVV: '',
    })
    const isPayEditOpen = ref(false)
    const isCardOpen = ref(false)
    const isPlanUpgradeDialogVisible = ref(false)
    const resolvePaymentStatusVariant = status => {
      if (status === 'Primary') return 'primary'
      if (status === 'Expired') return 'error'

      return 'secondary'
    }
    const editCardDetails = cardID => {
      const detail = paymentCards.value.filter(item => item.id === cardID)
      cardDetail.value = JSON.parse(JSON.stringify(detail[0]))
      Object.assign(cardDetail.value, {
        saveCardDetails: true,
      })

      if (isCardOpen.value) {
        isCardOpen.value = false
      } else {
        isCardOpen.value = true
      }
    }

    return {
      paymentCards,
      cardDetail,
      isPayEditOpen,
      isCardOpen,
      isEditAddressDialogVisible,
      deleteLoading,
      resolvePaymentStatusVariant,
      editCardDetails,
      isPlanUpgradeDialogVisible,
      isEdit,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
    }
  },
  computed: {
    ...mapState('card', ['cardInfo']),
  },
  watch: {
    isCardOpen() {
      if (!this.isCardOpen) {
        this.refreshInfo()
      }
    },
  },

  created() {
    this.refreshInfo()
    this.loadGMO()
  },

  methods: {
    expiredDate(val) {
      const [year, month] = val.split('/')

      return `${month}/${year}`
    },
    loadGMO() {
      if (document.getElementById('gmo_multipayment')) {
        return
      }
      const script = document.createElement('script')

      // script.src = 'https://static.mul-pay.jp/ext/js/token.js'
      script.src = process.env.VUE_APP_GMO_TOKEN_ENDPOINT

      script.id = 'gmo_multipayment'
      script.async = true
      script.onload = () => {
        // eslint-disable-next-line no-undef
        Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID)
      }
      document.body.appendChild(script)
    },
    ...mapActions('card', ['loadCardInfo', 'deleteCard']),
    handleDeleteCard() {
      this.deleteLoading = true
      this.deleteCard()
        .then(() => {
          this.isEdit = false
          this.paymentCards = []
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    refreshInfo() {
      this.$store.commit('app/setOverlayStatus', true)
      this.loadCardInfo()
        .then(() => {
          const card = this.cardInfo
          if (card.length === 0) {
            this.paymentCards = []
            this.isEdit = false
          } else {
            this.paymentCards = [
              {
                id: card.cardSeq,

                // cardImg: 'master.png',
                // imgWidth: '45',
                owner: card.holderName,
                cardNo: card.cardNo,
                cardExpires: `${card.expire.slice(0, 2)}/${card.expire.slice(2)}`,

                // cardStatus: 'Primary',
              },
            ]
            this.isEdit = true
          }
        })
        .finally(() => {
          this.$store.commit('app/setOverlayStatus', false)
        })
    },
  },
}
</script>
<style lang="scss">
#add-card-text {
  color: #ff6e40;
}
// @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
