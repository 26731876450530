<template>
  <v-app>
    <v-sheet class="pt-6" color="#F8ECDC" height="100%">
      <v-row class="mt-12 mx-6">
        <v-col cols="12" md="8" class="my-3">
          <Stepper
            :submit-order="submitOrder"
            :postage="postage"
            :discount="discount"
            :submit-status="submitStatus"
            :loading="loading"
            :to-top="toTop"
            :cancel-coup="cancelCoup"
            @scrollToTop="scrollToTop"
            @isLoading="isLoading"
            @getPostage="getPostage"
            @getDiscount="getDiscount"
            @doSubmitOrder="doSubmitOrder"
          />
        </v-col>
        <v-col cols="12" md="4" class="mt-3">
          <v-card class="px-6 my-3">
            <v-row class="pt-3" style="font-weight: bold; font-size: 20px">
              <v-col class="d-flex justify-start"> お支払い金額 </v-col>
            </v-row>
            <v-row style="font-weight: bold; font-size: 15px">
              <v-col class="d-flex justify-start">
                <span>商品名</span>
              </v-col>
              <v-col class="d-flex justify-end">
                <span>税込金額</span>
              </v-col>
            </v-row>
            <v-row v-for="(product, index) in cartList" :key="index" style="font-size: 15px">
              <v-col class="d-flex justify-start" cols="8">
                {{ product.name }}
              </v-col>
              <v-col class="d-flex justify-end" cols="4">
                ￥{{
                  cal(product)
                    ? cal(product)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                    : ''
                }}
              </v-col>
            </v-row>
            <v-row v-if="postage !== 0" style="font-size: 15px">
              <v-col class="d-flex justify-start"> 送料 </v-col>
              <v-col class="d-flex justify-end">
                ￥{{ postage.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
              </v-col>
            </v-row>
            <v-row style="font-size: 15px">
              <v-col class="d-flex justify-start"> 小計 </v-col>
              <v-col class="d-flex justify-end">
                ￥{{ (Number(total()) + postage).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
              </v-col>
            </v-row>
            <v-divider v-if="couponList.length !== 0" class="my-8"></v-divider>

            <div v-if="couponList.length !== 0">
              <v-row style="font-size: 15px">
                <v-col class="d-flex justify-start">
                  <strong>クーポン適用</strong>
                </v-col>
                <v-col class="d-flex justify-end">
                  <strong>割引金額</strong>
                </v-col>
              </v-row>
              <v-row
                v-for="(coupon, index) in couponList"
                :key="index"
                style="font-size: 15px; height: 30px"
                class="mb-3"
              >
                <v-col class="d-flex justify-start">
                  <div class="pointer">
                    <v-icon class="mr-1" @click="cancelCoupon(coupon)">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </div>
                  {{ coupon.coup_name }}
                </v-col>
                <v-col class="d-flex justify-end" style="color: red"> -￥{{ coupon.coup_discount }} </v-col>
              </v-row>
              <v-row style="font-size: 15px">
                <v-col class="d-flex justify-start"> 小計 </v-col>
                <v-col class="d-flex justify-end" style="color: red">
                  -￥{{
                    couponCal()
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                  }}
                </v-col>
              </v-row>
            </div>
            <v-divider v-if="cartList.length != 0" class="my-8"></v-divider>
            <v-row style="font-size: 15px">
              <v-col class="d-flex justify-start"> 合計（税込） </v-col>
              <v-col class="d-flex justify-end">
                ￥{{
                  final()
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                }}
              </v-col>
            </v-row>
            <div class="mt-10 mb-6">
              <v-btn
                v-if="submitOrder === true"
                depressed
                block
                color="#ff6e40"
                :loading="loading"
                class="py-6"
                @click="submit"
              >
                <span style="font-size: 15px; color: #fff">注文</span>
              </v-btn>
            </div>
            <div class="pb-10">
              <v-btn depressed block outlined class="py-6" @click="cart">
                <span style="font-size: 15px">取消</span>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <!-- <CouponTip
      :message="errorMsg"
      :dialog="couponTipDialog"
      @closeForm="closeCouponTip"
    ></CouponTip> -->
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

// import CouponTip from '@/components/Tip.vue'
import { mdiClose } from '@mdi/js'
import Stepper from './components/Stepper.vue'

export default {
  components: {
    Stepper,

    // CouponTip,
  },
  data: () => ({
    // errorMsg: '',
    icons: {
      mdiClose,
    },
    cancelCoup: '',
    submitOrder: false,
    submitStatus: false,
    postage: 0,
    discount: '',
    couponList: [],
    loading: false,
    toTop: false,
  }),
  computed: {
    ...mapState('cartStore', ['cartList']),
    ...mapState('shippingFee', ['ShippingFee']),
  },
  watch: {
    toTop() {
      if (this.toTop === true) {
        window.scrollTo(0, 0)
        this.toTop = false
      }
    },
  },
  created() {
    this.submitStatus = false
  },
  methods: {
    scrollToTop() {
      this.toTop = true
    },
    isLoading() {
      this.loading = !this.loading
    },
    doSubmitOrder() {
      this.submitOrder = !this.submitOrder
    },
    submit() {
      this.submitStatus = true
    },
    getPostage(value) {
      this.postage = value
    },
    getDiscount(value) {
      this.discount = value
      const index = this.couponList.findIndex(obj => obj.coup_name === this.discount.coup_name)
      if (index === -1) {
        this.couponList.push(this.discount)
      }
      if (index !== -1) {
        this.couponList.splice(index, 1)
      }
    },
    cal(product) {
      return product.price_normal * product.count
    },
    cancelCoupon(val) {
      console.log('val', val)
      this.cancelCoup = val
    },
    couponCal() {
      let couponTotal = 0
      for (let i = 0; i < this.couponList.length; i += 1) {
        couponTotal += this.couponList[i].coup_discount
      }

      return couponTotal
    },
    final() {
      let price = Number(this.total()) + this.postage
      this.couponList.forEach((item, index) => {
        // price -= this.couponList[index].coup_discount
        price -= this.couponList[index].coup_discount
      })

      return price
    },
    total() {
      let price = 0
      for (let i = 0; i < this.cartList.length; i += 1) {
        price += this.cartList[i].price_normal * this.cartList[i].count
      }

      return price.toFixed(0)
    },
    home() {
      this.$router.push('/')
    },
    cart() {
      this.$router.push('/cart')
    },
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
