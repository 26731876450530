<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="isCardOpen" max-width="800" @click:outside="closeCard()">
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <Card
        ref="credit_card"
        :is-card-open.sync="isCardOpen"
        :card-detail="cardDetail"
        :action="action"
        @sendToken="receiveToken"
      />
      <v-card-text class="mt-5">
        <v-row justify="end">
          <v-col cols="3">
            <v-btn color="secondary" width="100%" outlined @click="closeCard()"> 取消 </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" width="100%" :loading="submitLoading" @click="confirm"> 確認 </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'

import agent from '../../api/agent'
import Card from './components/Card.vue'

export default {
  components: {
    Card,
  },
  props: {
    isCardOpen: {
      type: Boolean,
      required: true,
    },
    cardDetail: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  setup() {
    const submitLoading = ref(false)
    const info = ref({
      cardNo: '',
      cardExpires: '',
      CVV: '',
      owner: '',
      cardImg: '',
    })
    const date = ref('')
    const modal = ref(false)
    const errMsg = ref('')

    return {
      info,
      submitLoading,
      date,
      modal,
      errMsg,
    }
  },
  watch: {
    isCardOpen() {
      if (this.action === 'create') {
        console.log('create')
        this.clearCard()
      } else {
        console.log('edit')
        this.info = {
          cardNo: this.cardDetail.cardNo,
          cardExpires: this.cardDetail.cardExpires,
          CVV: this.cardDetail.CVV,
          owner: this.cardDetail.owner,
        }
      }
    },
  },

  methods: {
    resetValidation() {
      this.$v.$reset()
    },
    confirm() {
      this.setLoading(true)
      console.log(this.$refs.credit_card)
      this.$refs.credit_card.submitCard()
    },

    setLoading(val) {
      this.submitLoading = val
    },
    clearCard() {
      this.$nextTick(() => {
        this.info = {
          cardNo: '',
          cardExpires: '',
          CVV: '',
          owner: '',
        }
        this.date = ''
        this.errMsg = ''
      })
      console.log('this.info', this.info)
    },
    receiveToken(card) {
      console.log('receive-token', card)
      if (card !== null) {
        agent.Card.modify({
          cardNo: card.maskedCardNo,
          cardToken: card.token,
        })
          .then(() => {
            this.closeCard()
          })
          .catch(() => {
            this.$refs.credit_card.errMsg = 'カード登録に失敗しました。'
          })
          .finally(() => {
            this.setLoading(false)
          })
      } else {
        this.setLoading(false)
      }
    },
    closeCard() {
      this.setLoading(false)

      this.$emit('update:is-card-open', false)
    },
  },
}
</script>
