<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" color="#ff6e40">
        <span>お届け先を選択</span>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2" color="#ff6e40">
        <span>支払い情報</span>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 3" step="3" color="#ff6e40">
        <span>商品と配送情報を確認</span>
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card outlined color="rgb(0,0,0,0)" class="px-2">
          <Address1 :e1="e1" :form="form" :postage="postage" @e1Change="e1Change" @getForm="getForm" />
        </v-card>
      </v-stepper-content>
      <PaymentStepperContent
        :submit-order="submitOrder"
        :step="2"
        :postage="postage"
        @clearPostage="clearPostage"
        @doSubmitOrder="doSubmitOrder"
        @prevPage="prevPage"
        @nextPage="nextPage"
      ></PaymentStepperContent>
      <Check
        :submit-order="submitOrder"
        :step="3"
        :form="form"
        :postage="postage"
        :discount="discount"
        :submit-status="submitStatus"
        :loading="loading"
        :cancel-coup="cancelCoup"
        @isLoading="isLoading"
        @doSubmitOrder="doSubmitOrder"
        @getDiscount="getDiscount"
        @prevPage="prevPage"
        @clearPostage="clearPostage"
        @clearForm="clearForm"
      />
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import Address1 from './Address.vue'
import Check from './Check.vue'
import PaymentStepperContent from './PaymentStepperContent.vue'

export default {
  components: {
    Address1,
    Check,
    PaymentStepperContent,
  },
  props: ['postage', 'discount', 'submitStatus', 'submitOrder', 'loading', 'toTop', 'cancelCoup'],
  data: () => ({
    e1: 1,
    form: '',
  }),
  methods: {
    isLoading() {
      this.$emit('isLoading')
    },
    doSubmitOrder() {
      this.$emit('doSubmitOrder')
    },
    prevPage(page) {
      this.e1 = page > 1 ? page - 1 : page
      this.$emit('scrollToTop')
    },
    nextPage(page) {
      this.e1 = page < 3 ? page + 1 : page
      this.$emit('scrollToTop')
    },
    e1Change(value) {
      this.e1 = value
      this.$emit('scrollToTop')
    },
    getForm(value1, value2) {
      this.form = value1
      this.$emit('getPostage', value2)
    },
    clearForm() {
      this.form = ''
    },
    clearPostage(value) {
      this.$emit('getPostage', value)
    },
    getDiscount(value) {
      this.$emit('getDiscount', value)
    },
    addressedit() {
      this.$router.push('/addressedit')
    },
    addresscreate() {
      this.$router.push('/addresscreate')
    },
    back() {
      this.$router.push('/cart')
    },
  },
}
</script>
